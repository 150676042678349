import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularPlugin, ApplicationInsights, AppInsightsErrorHandlerService } from 'framework';

import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';

import { SolisSessionService } from '../session/solis-session.service';
import { SessionKeys } from '../../constants/session-keys';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  private postLoginUrlKey: string = 'postLoginUrl';
  private launchIdKey: string = 'launchId';
  private launchThemeKey: string = 'theme';

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private location: Location,
    private solisSessionService: SolisSessionService,
    private router: Router
  ) {}

  initialize(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // If cookies are disabled, do not initialize, resolve and report the issue.
      if (!navigator.cookieEnabled) {
        resolve();
        return;
      }

      // Normally when using window.open(url, '_blank'), the new window's
      // session inherits the session from the window that opened it and starts from there.
      // This is *NOT* the case when the opening window is inside an iframe, like we frequently are.
      // The below method ensures that when we start in a new tab, we *always* get the session
      // from the opening window as a starting point.
      this.restoreSessionFromParent();

      const appInsights = this.initializeApplicationInsights();

      // Check to see if the site is being launched via an external launch (Envestnet for instance)
      const launchIdFromQueryString = this.getParamValueFromQueryString(this.launchIdKey);

      const externalUserIdFromQueryString = this.getParamValueFromQueryString(SessionKeys.activeExternalUserId);

      const themeFromQueryString = this.getParamValueFromQueryString(this.launchThemeKey);

      const postLoginUrl = this.getWindowLocationHref();

      const performance = this.getWindowPerformance();

      // If we are launching in, pressing the back button will go back the the initialization screen and not back
      // to the external site we came from. If the user presses the Back button on the init screen for launch, then
      // a subsequent back will be triggered and cause the user to go back to the external site as expected.
      if (launchIdFromQueryString) {
        // If a launchId appears in the query string, we're assuming a new launch into the system.  To prevent any
        // old session data from carrying over, we will clear the session entirely and start anew.
        sessionStorage.clear();

        if (performance) {
          const performanceEntries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];

          if (performanceEntries && performanceEntries[performanceEntries.length - 1]?.type === 'back_forward') {
            history.back();
          }
        }
      }

      // session_state indicates an IS4 redirect and we want to ignore those
      if (!postLoginUrl.toLowerCase().includes('session_state') && !postLoginUrl.toLowerCase().includes('launchid')) {
        sessionStorage.setItem(this.postLoginUrlKey, postLoginUrl);
      }

      this.oidcSecurityService.checkAuth().subscribe(async (loginResponse) => {
        if (!loginResponse.isAuthenticated) {
          if (launchIdFromQueryString) {
            // Shift launchId from query string to session storage
            this.moveLaunchParamsToSessionStorage(launchIdFromQueryString, externalUserIdFromQueryString ?? '', themeFromQueryString ?? '');
          }

          this.authorize();
        } else {
          // If a user is already authenticated, it may end up being a different user than
          // is being requested for this launch. We need to log off the current user
          // from Solis (the new launch user will ALREADY be logged in on the IdentityServer)
          // and log them back in
          if (launchIdFromQueryString) {
            // Shift launchId from query string to session storage so when we're redirected back
            // from the IdentityServer, we don't end up back here
            this.moveLaunchParamsToSessionStorage(launchIdFromQueryString, externalUserIdFromQueryString ?? '', themeFromQueryString ?? '');

            // Log the current user out from Solis
            this.oidcSecurityService.logoffLocal();

            // As mentioned above, the new incoming user will already be logged into the IdentityServer
            // based on how the external launch process works. The login here will be transparent to the user.
            // They won't be prompted for credentials since they're already logged in on the IdentityServer
            this.authorize();

            // Return immediately
            resolve();
            return;
          }

          // Once logged in set the user name so it gets logged as the AuthId into Insight logs
          if (appInsights) {
            appInsights.setAuthenticatedUserContext(loginResponse.userData.name, undefined, false);
          }

          // The IdentityServer *always* redirects directly to the home page. If a URL was dropped in
          // to a sub route, we need to manually route there after authentication is complete
          const postLoginUrlFromSessionStorage = sessionStorage.getItem(this.postLoginUrlKey);

          // If an external launch is in progress, this will have been set above based on launchIdFromQueryString
          // This will null for standard login / PI direct
          const launchIdFromSessionStorage = sessionStorage.getItem(this.launchIdKey);

          const themeIdFromSessionStorage = sessionStorage.getItem(this.launchThemeKey);

          await this.solisSessionService.initialize(
            postLoginUrlFromSessionStorage,
            loginResponse.userData.sub,
            launchIdFromSessionStorage,
            themeIdFromSessionStorage
          );
        }

        resolve();
      });
    });
  }

  logout(): void {
    sessionStorage.removeItem(this.launchIdKey);
    this.oidcSecurityService.logoff();
  }

  private authorize(): void {
    const firmCodeValue = this.getParamValueFromQueryString('firmCode');
    const firmTypeValue = this.getParamValueFromQueryString('firmType') || 'DtccDistributor';

    let authOptions: AuthOptions | undefined = undefined;

    if (firmCodeValue) {
      authOptions = {
        customParams: {
          firmCode: firmCodeValue,
          firmType: firmTypeValue
        }
      };
    }

    this.oidcSecurityService.authorize(undefined, authOptions);
  }

  /* istanbul ignore next */
  private initializeApplicationInsights(): ApplicationInsights | null {
    if (!environment.applicationInsights.connectionString) {
      return null;
    }

    const angularPlugin = new AngularPlugin();

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.applicationInsights.connectionString,
        enableCorsCorrelation: environment.applicationInsights.enableCorsCorrelation,
        enableRequestHeaderTracking: environment.applicationInsights.enableRequestHeaderTracking,
        enableResponseHeaderTracking: environment.applicationInsights.enableResponseHeaderTracking,
        correlationHeaderExcludedDomains: environment.applicationInsights.correlationHeaderExcludedDomains,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            router: this.router,
            errorServices: [new AppInsightsErrorHandlerService()]
          }
        }
      }
    });

    appInsights.loadAppInsights();

    return appInsights;
  }

  // We should ignore testing, it becomes too costly when using window.parent.opener.
  /* istanbul ignore next */
  private restoreSessionFromParent(): void {
    try {
      // If the current session storage already has a sessionId set, that means
      // that a session has already been initialized and we don't need to do anything here.
      const sessionId = sessionStorage.getItem(SessionKeys.sessionId);

      if (sessionId) {
        return;
      }

      // We always want to restore from window.parent.opener.
      // If it's not available, this is a brand new session
      if (!window.parent.opener) {
        return;
      }

      let parentSessionStorage = null;

      try {
        // If we don't own the opener we'll get a permission error here just by trying to read the property
        parentSessionStorage = window.parent.opener.sessionStorage;
      } catch (serr) {
        // Just swallow it and return
        return;
      }

      // First make sure we're starting from an empty session
      sessionStorage.clear();

      // Copy parent's session into our session
      for (let i = 0; i < parentSessionStorage.length; i++) {
        const parentSessionKey = parentSessionStorage.key(i)!;
        const parentSessionValue = parentSessionStorage.getItem(parentSessionKey);
        sessionStorage.setItem(parentSessionKey, parentSessionValue);
      }
    } catch (err) {
      console.log('Unable to restore session from parent.', err);
    }
  }

  private moveLaunchParamsToSessionStorage(launchId: string, externalUserId: string, theme: string): void {
    // Set the launchId in sessionStorage. sessionStorage is cleared after the tab (NOT WINDOW!) is closed
    // in most modern browsers
    sessionStorage.setItem(this.launchIdKey, launchId);
    sessionStorage.setItem(SessionKeys.activeExternalUserId, externalUserId);

    if (theme !== '') {
      sessionStorage.setItem(this.launchThemeKey, theme);
    }

    // To avoid getting stuck in an endless loop, we need to remove launchId from the
    // query string. Otherwise we'll end up right back here when the new correct user
    // is logged in.
    this.location.replaceState(location.pathname, new HttpParams().toString());
  }

  private getParamValueFromQueryString(paramName: string): string | null | undefined {
    const url = this.getWindowLocationHref();
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  /* istanbul ignore next */
  private getWindowLocationHref(): string {
    return window.location.href.toString();
  }

  /* istanbul ignore next */
  private getWindowPerformance(): Performance {
    return window.performance;
  }
}
